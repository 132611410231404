import {
  ContentfulPage,
  Footer,
  Header
} from "@life-without-barriers/react-components"
import FormProvider, { MSTForm, StandardForm } from "../components/FormProvider"
import {
  Location,
  SiteMetaData,
  contentTypes
} from "@life-without-barriers/gatsby-common"

import React from "react"
import { graphql } from "gatsby"
import loadable from "@loadable/component"

const DisabilityHeader = loadable(
  () => import("../components/disability/Header")
)
const DisabilityFooter = loadable(
  () => import("../components/disability/Footer")
)
const CarerGuideHeader = loadable(
  () => import("../components/carer-guide/Header")
)
const CarerGuideFooter = loadable(
  () => import("../components/carer-guide/Footer")
)
const CaringSpaceFooter = loadable(
  () => import("../components/foster-care/FooterCaring")
)
const FosterCareHeader = loadable(
  () => import("../components/foster-care/Header")
)
const FosterCareFooter = loadable(
  () => import("../components/foster-care/Footer")
)
const AgedCareFooter = loadable(() => import("../components/aged-care/Footer"))
const PartneringWithPurposeHeader = loadable(
  () => import("../components/our-approach/partnering-with-purpose/Header")
)

interface PageBuilderProps {
  location: Location
  data: {
    site: contentTypes.Site
    pageBuilder: contentTypes.PageBuilder
  }
}

const getPathElements = (
  location: Location,
  siteMetaData: SiteMetaData
): {
  header: React.ReactElement
  footer: React.ReactElement
  showSecondaryNav: boolean
} => {
  if (location.pathname.startsWith("/disability/")) {
    return {
      header: <DisabilityHeader />,
      footer: <DisabilityFooter />,
      showSecondaryNav: false
    }
  } else if (location.pathname.startsWith("/foster-care/support/carer-guide")) {
    return {
      header: (
        <CarerGuideHeader location={location} siteMetadata={siteMetaData} />
      ),
      footer: <CarerGuideFooter />,
      showSecondaryNav: false
    }
  } else if (location.pathname.startsWith("/foster-care/caring-space")) {
    return {
      header: <FosterCareHeader />,
      footer: <CaringSpaceFooter />,
      showSecondaryNav: false
    }
  } else if (location.pathname.startsWith("/foster-care")) {
    return {
      header: <FosterCareHeader />,
      footer: <FosterCareFooter />,
      showSecondaryNav: false
    }
  } else if (location.pathname.startsWith("/aged-care")) {
    return {
      header: <Header />,
      footer: <AgedCareFooter />,
      showSecondaryNav: true
    }
  } else if (
    location.pathname.startsWith("/our-approach/partnering-with-purpose")
  ) {
    return {
      header: <PartneringWithPurposeHeader />,
      footer: <Footer hideContactDetails={true} hideUsefulLinks={true} />,
      showSecondaryNav: false
    }
  }
  return { header: <Header />, footer: <Footer />, showSecondaryNav: true }
}

const ContentfulPageBuilder = ({
  data: { pageBuilder, site },
  location
}: PageBuilderProps) => {
  const {
    metadata,
    theme,
    banner,
    breadcrumbsEnabled,
    shareEnabled,
    navigationTiles,
    sections
  } = pageBuilder
  const bannerSection = {
    breadcrumbsEnabled,
    shareEnabled,
    path: location.pathname,
    ...banner
  }
  const pinnedSections = navigationTiles
    ? [bannerSection, navigationTiles]
    : [bannerSection]
  const { header, footer, showSecondaryNav } = getPathElements(
    location,
    site.siteMetadata
  )
  return (
    <ContentfulPage
      path={location.pathname}
      theme={theme}
      seoMetadata={metadata}
      siteMetadata={site.siteMetadata}
      banner={bannerSection}
      header={header}
      sections={[...pinnedSections, ...sections]}
      showSecondaryNav={showSecondaryNav}
      footer={footer}
      formProvider={(formOptions: StandardForm | MSTForm) =>
        FormProvider(formOptions)
      }
    />
  )
}

export const query = graphql`
  query ($id: String) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    pageBuilder: contentfulPageBuilder(id: { eq: $id }) {
      ...ContentfulPageBuilder
    }
  }
`

export default ContentfulPageBuilder
